import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Button } from '@material-ui/core';

import { inject, observer } from 'mobx-react';
import { Error } from '@containers/signIn/components/FormError';
import { LoginTextField } from '@containers/signIn/components/LoginTextField';
import logo from '@app/assets/logo.png';
import { history } from '@app/history';
import routes from '@routes';
import { PageContainer } from '@styles/globalStyles';

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: '0px 1px 13px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'white',
    padding: '30px 30px 0px 30px',
    borderRadius: '10px',
    color: '#808080',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: '#24255f',
    color: 'white',
  },
  resetPassword: {
    marginBottom: 8,
    backgroundColor: '#24255f',
    color: 'white',
  },
  subtitle: {
    marginTop: 10,
    fontSize: 20,
  },
  signout: {
    marginTop: 10,
    fontSize: 20,
    color: '#eb1765',
  },
  reset: {
    marginTop: 10,
    fontSize: 17,
    color: 'green',
  },
  forbidden: {
    marginTop: 30,
    fontSize: 30,
    color: '#eb1765',
  },
}));

const SignInPage = ({
  authStore: { login, authError, setOrigin, logout, isAuthenticated },
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [otp, setOtp] = useState('');

  const url = new URL(window.location.href);
  const origin = url.searchParams.get('origin');
  const signout = url.searchParams.get('signout') === 'true';
  const reset = url.searchParams.get('reset') === 'true';
  const newPassword = url.searchParams.get('newPassword') === 'true';

  setOrigin(origin);

  if (signout) {
    logout(signout);
  }

  if (isAuthenticated()) {
    history.push(routes.services);
  }

  async function submit(event) {
    event.preventDefault();
    await login(email, password, otp);
  }

  return (
    <PageContainer>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt="BriefcaseSSO" style={{ height: 160 }} />
          <div className={classes.subtitle}>
            Sign in for Tool and Popcorn services
          </div>
          {signout && (
            <div className={classes.signout}>You have been signed out.</div>
          )}
          {reset && (
            <div className={classes.reset}>
              Email with reset instructions has been sent.
            </div>
          )}
          {newPassword && (
            <div className={classes.reset}>
              Password has been successfully changed!
            </div>
          )}
          <form className={classes.form} noValidate onSubmit={submit}>
            <LoginTextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              onInput={e => setEmail(e.target.value.toLowerCase())}
              autoFocus
            />
            <LoginTextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              onInput={e => setPassword(e.target.value)}
            />
            <LoginTextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="otp"
              label="Authentication token"
              type="otp"
              id="otp"
              onInput={e => setOtp(e.target.value.trim().replace(' ', ''))}
            />
            {authError !== '' && <Error>{authError}</Error>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Login
            </Button>
            <Button
              onClick={() => history.push(routes.auth.resetPassword[0])}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.resetPassword}
            >
              Reset Password
            </Button>
          </form>
        </div>
      </Container>
    </PageContainer>
  );
};

export default inject('authStore')(observer(SignInPage));
