import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Checkbox,
  Container,
  CssBaseline,
  IconButton,
  withStyles,
} from '@material-ui/core';

import { inject, observer } from 'mobx-react';
import ClearIcon from '@material-ui/icons/Clear';
import { history } from '@app/history';
import routes from '@routes';
import Loader from '@components/Loader';
import QRCode from 'react-qr-code';
import { LoginTextField } from '@containers/signIn/components/LoginTextField';
import { ReminderDialog } from '@containers/preferences/components/ReminderDialog';
import { PageContainer } from '@styles/globalStyles';

const BriefcaseBlueCheckbox = withStyles({
  root: {
    '&$checked': {
      color: '#24255f',
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />);

const useStyles = makeStyles(() => ({
  paper: {
    minHeight: 590,
    boxShadow: '0px 1px 13px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'white',
    padding: 30,
    paddingTop: 20,
    borderRadius: '10px',
    color: '#808080',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 20,
    height: 40,
    width: '100%',
    borderBottom: '1px #808080 solid',
  },
  title: {
    paddingTop: 5,
  },
  label: {
    paddingTop: 10,
  },
  checkboxContainer: {
    display: 'flex',
    marginTop: 20,
    justifyContent: 'space-between',
    width: '100%',
    fontSize: 16,
  },
  qrContainer: {
    marginTop: 20,
    textAlign: 'center',
    width: '100%',
    fontSize: 16,
  },
  verificationInfo: {
    textAlign: 'center',
    color: 'red',
    paddingTop: 10,
    fontSize: 17,
  },
  verifyButton: {
    backgroundColor: '#24255f',
    color: 'white',
    height: 56,
    marginLeft: 10,
    marginTop: 16,
  },
  verificationContainer: {
    display: 'inline-flex',
    width: '100%',
  },
  codeInfo: {
    margin: 10,
    fontSize: 17,
  },
}));

const Preferences = ({
  authStore: {
    toggleTwoFactorAuthentication,
    isLoading,
    twoFactorEnabled,
    qrUri,
    otpSecret,
    fetchCodes,
    verified,
    verify,
    clear2FA,
    showSuccessMessage,
    showReminderMessage,
    setShowReminderMessage,
  },
}) => {
  const classes = useStyles();
  const [verificationCode, setVerificationCode] = useState('');

  useEffect(() => {
    fetchCodes();
  }, []);

  return (
    <PageContainer>
      <ReminderDialog
        open={showReminderMessage}
        handleClose={() => setShowReminderMessage(false)}
      />
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <div className={classes.header}>
            <div className={classes.title}>Preferences</div>
            <IconButton
              onClick={() => {
                clear2FA();
                history.push(routes.services);
              }}
              style={{ padding: 8 }}
            >
              <ClearIcon />
            </IconButton>
          </div>
          {isLoading && <Loader />}
          {!twoFactorEnabled && (
            <div className={classes.checkboxContainer}>
              <div className={classes.label}>2FA code</div>
              <BriefcaseBlueCheckbox
                value={twoFactorEnabled}
                checked={twoFactorEnabled}
                name="enabled"
                onChange={e => toggleTwoFactorAuthentication(e.target.checked)}
              />
            </div>
          )}
          {twoFactorEnabled && qrUri && otpSecret && (
            <div className={classes.qrContainer}>
              <QRCode size={200} value={qrUri} />
              <div className={classes.codeInfo}>
                Scan this QR code with your authenticator app (e.g. Google
                Authenticator) or use following code in your authenticator app:{' '}
                <b>{otpSecret}</b>
              </div>
            </div>
          )}
          {showSuccessMessage && (
            <div
              className={classes.verificationInfo}
              style={{ color: 'green' }}
            >
              2FA successfully enabled
            </div>
          )}
          {twoFactorEnabled && !verified && (
            <div>
              <div className={classes.verificationInfo}>
                Provide a code from your authenticator app for verification (2FA
                will be enabled after a positive verification)
              </div>
              <div className={classes.verificationContainer}>
                <LoginTextField
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="otp"
                  label="Authentication token"
                  type="otp"
                  id="otp"
                  onInput={e =>
                    setVerificationCode(e.target.value.trim().replace(' ', ''))
                  }
                />
                <Button
                  onClick={() => verify(verificationCode)}
                  variant="contained"
                  className={classes.verifyButton}
                >
                  Verify
                </Button>
              </div>
            </div>
          )}
        </div>
      </Container>
    </PageContainer>
  );
};

export default inject('authStore')(observer(Preferences));
