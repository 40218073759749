import React, { useEffect } from 'react';
import { ThemeProvider } from '@emotion/react';
import { Route, Router, Switch, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import { history } from '@app/history';

import GlobalStyle from '@styles/globalStyles';
import routes from '@routes';

import SignInPage from '@containers/signIn';

import theme from '@styles/theme';
import PrivateRoute from '@components/PrivateRoute';
import Services from '@containers/services';
import ForbiddenPage from '@containers/forbidden';
import Preferences from '@containers/preferences';
import Footer from '@components/Footer';
import ResetPasswordPage from '@containers/resetPassword';
import { IntercomProvider } from 'react-use-intercom';
import ReactGA from 'react-ga4';

const PageWrapper = styled.div`
  background-color: #eeefef;
  height: 100vh;
`;

const GoogleAnalytics = () => {
  useEffect(() => {
    if (process.env.NODE_ENV !== 'dd') {
      const TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
      ReactGA.initialize(TRACKING_ID);
    }
  }, []);

  const location = useLocation();
  useEffect(() => {
    if (process.env.NODE_ENV !== 'dd') {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    }
  }, [location]);
  return null;
};

const App = () => (
  <ThemeProvider theme={theme}>
    <GlobalStyle />
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID} autoBoot>
      <PageWrapper>
        <Router history={history}>
          <GoogleAnalytics />
          <Switch>
            <Route exact path={routes.auth.signIn} component={SignInPage} />
            <Route
              exact
              path={routes.auth.resetPassword}
              component={ResetPasswordPage}
            />
            <Route
              exact
              path={routes.auth.newPassword}
              component={ResetPasswordPage}
            />
            <Route exact path={routes.forbidden} component={ForbiddenPage} />
            <PrivateRoute exact path={routes.services} component={Services} />
            <PrivateRoute
              exact
              path={routes.preferences}
              component={Preferences}
            />
          </Switch>
        </Router>
        <Footer />
      </PageWrapper>
    </IntercomProvider>
  </ThemeProvider>
);

export default App;
