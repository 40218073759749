import React from 'react';
import { Global, css } from '@emotion/react';
import reset from 'emotion-reset';
import styled from '@emotion/styled';
// import { breakpoint } from 'styles/breakpoints';

const GlobalStyle = () => (
  <Global
    styles={css`
      @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500&display=swap');
      ${reset}

      * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }

      body {
        color: #000;
        font-family: 'Roboto', sans-serif;
      }

      .drift-frame-controller {
        bottom: 80px !important;
      }
    `}
  />
);

export const PageContainer = styled.div`
  height: 100vh;
  padding: 20px 100px 100px;
  overflow: scroll;

  @media screen and (max-width: 850px) {
    padding: 50px 0 100px;
  }
`;

export default GlobalStyle;
