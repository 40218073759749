import React from 'react';
import { Flex } from 'rebass';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function Loader({ size, ...props }) {
  return (
    <Flex width={1} justifyContent="center" py={40} {...props}>
      <CircularProgress size={size} />
    </Flex>
  );
}
