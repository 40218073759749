import { makeAutoObservable, runInAction } from 'mobx';
import { API, APIRoutes, setApiHeaders } from '@app/api';
import { history } from '@app/history';
import routes from '@routes';
import Cookie from 'js-cookie';

export class AuthStore {
  isLoading = false;

  authError = null;

  origin = '';

  availableServices = [];

  twoFactorEnabled = false;

  qrUri = null;

  otpSecret = null;

  verified = false;

  showReminderMessage = false;

  showSuccessMessage = false;

  suffix = process.env.REACT_APP_COOKIE_SUFFIX;

  email = null;

  constructor() {
    makeAutoObservable(this);
    const email = Cookie.get(`analyst_email${this.suffix}`);
    if (email) {
      this.email = email;
    }
  }

  setOrigin = origin => {
    runInAction(() => {
      this.origin = origin;
    });
  };

  login = async (email, password, otp) => {
    this.isLoading = true;
    this.authError = null;
    try {
      const {
        data: {
          authentication_token: token,
          analyst_id: analystId,
          available_services: availableServices,
          otp_required: twoFactorEnabled,
        },
      } = await API.post(APIRoutes.SIGN_IN, {
        analyst: { email, password, otp_attempt: otp },
      });
      setApiHeaders(token, email);
      runInAction(() => {
        this.availableServices = availableServices;
        this.twoFactorEnabled = twoFactorEnabled;
        this.email = email;
      });

      Cookie.set(`analyst_email${this.suffix}`, email, {
        expires: 365,
        domain: '.briefcase.news',
      });
      Cookie.set(`analyst_token${this.suffix}`, token, {
        expires: 365,
        domain: '.briefcase.news',
      });
      Cookie.set(`analyst_id${this.suffix}`, analystId, {
        expires: 365,
        domain: '.briefcase.news',
      });

      if (this.origin) {
        window.location.href = this.origin;
      } else {
        history.push(routes.services);
      }
    } catch (e) {
      runInAction(() => {
        this.authError = e.error;
        this.authenticationToken = null;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
    return null;
  };

  resetPassword = async (email, otp) => {
    this.isLoading = true;
    this.authError = null;
    try {
      await API.post(APIRoutes.RESET_PASSWORD, {
        analyst: { email, otp_attempt: otp },
      });
      history.push(`${routes.auth.signIn}?reset=true`);
    } catch (e) {
      console.log(e);
      runInAction(() => {
        this.authError = e.errors;
        this.authenticationToken = null;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
    return null;
  };

  newPassword = async (password, passwordConfirmation, token) => {
    if (password !== passwordConfirmation) {
      this.authError = 'Password confirmation does not match password';
      return;
    }
    this.isLoading = true;
    this.authError = null;
    try {
      await API.patch(APIRoutes.RESET_PASSWORD, {
        analyst: {
          password,
          password_confirmation: passwordConfirmation,
          reset_password_token: token,
        },
      });
      history.push(`${routes.auth.signIn}?newPassword=true`);
    } catch (e) {
      runInAction(() => {
        const {
          errors: {
            password: passwordErrors,
            password_confirmation: confirmationErrors,
            reset_password_token: tokenErrors,
          },
        } = e;
        let error = null;
        if (passwordErrors) {
          error = `Password ${passwordErrors.toString()}`;
        } else if (confirmationErrors) {
          error = `Password confirmation ${confirmationErrors.toString()}`;
        } else if (tokenErrors) {
          error = `Reset token ${tokenErrors.toString()}`;
        }
        this.authError = error;
        this.authenticationToken = null;
      });
    } finally {
      runInAction(() => {
        this.isLoading = false;
      });
    }
    return null;
  };

  clearCookies = () => {
    Cookie.remove(`analyst_email${this.suffix}`, {
      domain: '.briefcase.news',
    });
    Cookie.remove(`analyst_token${this.suffix}`, {
      domain: '.briefcase.news',
    });
    Cookie.remove(`analyst_id${this.suffix}`, {
      domain: '.briefcase.news',
    });
  };

  isAuthenticated = () => {
    const email = Cookie.get(`analyst_email${this.suffix}`);
    const token = Cookie.get(`analyst_token${this.suffix}`);
    if (token && email) {
      setApiHeaders(token, email);
    }

    return email && token;
  };

  logout = async signout => {
    if (this.isAuthenticated()) {
      this.clearCookies();
      await API.delete(APIRoutes.SIGN_OUT);
      if (!signout) {
        history.push(routes.auth.signIn);
      }
    }
  };

  fetchAvailableServices = async () => {
    this.isLoading = true;
    try {
      const {
        data: { available_services: availableServices },
      } = await API.get(APIRoutes.AVAILABLE_SERVICES);
      runInAction(() => {
        this.availableServices = availableServices;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
    this.isLoading = false;
  };

  toggleTwoFactorAuthentication = async enabled => {
    this.isLoading = true;
    const showReminderMessage =
      this.twoFactorEnabled && !enabled && this.verified;
    try {
      const {
        data: { qr_uri: qrUri, secret: otpSecret },
      } = await API.post(APIRoutes.TOGGLE_TWO_FACTOR, {
        otp_required: enabled,
      });
      runInAction(() => {
        this.twoFactorEnabled = !!(qrUri && otpSecret);
        this.qrUri = qrUri;
        this.otpSecret = otpSecret;
        this.verified = false;
        this.showReminderMessage = showReminderMessage;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  fetchCodes = async () => {
    this.isLoading = true;
    try {
      const {
        data: {
          otp_required: twoFactorEnabled,
          qr_uri: qrUri,
          secret: otpSecret,
        },
      } = await API.get(APIRoutes.OTP_SECRET);
      runInAction(() => {
        this.twoFactorEnabled = twoFactorEnabled && !!(qrUri && otpSecret);
        this.qrUri = qrUri;
        this.otpSecret = otpSecret;
        this.verified = twoFactorEnabled && !!(qrUri && otpSecret);
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  verify = async verificationCode => {
    this.isLoading = true;
    try {
      const {
        data: { verified },
      } = await API.post(APIRoutes.VERIFY, {
        verification_code: verificationCode,
      });
      runInAction(() => {
        this.verified = verified;
        this.twoFactorEnabled = verified;
        this.showSuccessMessage = verified;
      });
    } catch (e) {
      console.log(e);
    } finally {
      this.isLoading = false;
    }
  };

  clear2FA = () => {
    runInAction(() => {
      this.twoFactorEnabled = false;
      this.qrUri = null;
      this.otpSecret = null;
      this.showSuccessMessage = false;
      this.verified = false;
    });
  };

  setShowSuccessMessage = value => {
    runInAction(() => {
      this.showSuccessMessage = value;
    });
  };

  setShowReminderMessage = value => {
    runInAction(() => {
      this.showReminderMessage = value;
    });
  };
}

export default new AuthStore();
