import axios from 'axios';
import { authStore } from '@stores';
import Cookie from 'js-cookie';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

API.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      authStore.logout();
    }
    return Promise.reject(error.response.data);
  },
);

export function setApiHeaders(analystToken, analystEmail) {
  const suffix = process.env.REACT_APP_COOKIE_SUFFIX;

  Cookie.set(`analyst_email${suffix}`, analystEmail, {
    expires: 365,
    domain: '.briefcase.news',
  });

  Cookie.set(`analyst_token${suffix}`, analystToken, {
    expires: 365,
    domain: '.briefcase.news',
  });

  if (API) {
    API.defaults.headers.common = {
      'X-ANALYST-EMAIL': analystEmail,
      'X-ANALYST-TOKEN': analystToken,
    };
  }
}

const APIRoutes = {
  SIGN_IN: 'tool/analysts/sign_in',
  RESET_PASSWORD: 'tool/analysts/password.json',
  SIGN_OUT: 'tool/analysts/sign_out.json',
  AVAILABLE_SERVICES: `sso/analysts/available_services.json`,
  TOGGLE_TWO_FACTOR: `sso/analysts/toggle_two_factor_authentication.json`,
  OTP_SECRET: `sso/analysts/otp_secret.json`,
  VERIFY: `sso/analysts/verify_two_factor_authentication.json`,
};

export { API, APIRoutes };
