export default {
  auth: {
    signIn: '/',
    newPassword: ['/change-password/:id', '/change_password/:id'],
    resetPassword: ['/change-password', '/change_password'],
  },
  services: '/services',
  forbidden: '/forbidden',
  preferences: '/preferences',
};
