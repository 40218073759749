import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export const ReminderDialog = ({ open, handleClose }) => (
  <Dialog open={open} onClose={handleClose}>
    <DialogTitle style={{ color: '#24255f' }}>2FA disabling</DialogTitle>
    <DialogContent>
      <DialogContentText>
        Remember to remove old codes from your authenticator app to avoid
        mistakes.
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button
        onClick={handleClose}
        style={{ backgroundColor: '#24255f', color: 'white' }}
        autoFocus
      >
        Ok
      </Button>
    </DialogActions>
  </Dialog>
);
