import React from 'react';
import { inject, observer } from 'mobx-react';
import { Route, Redirect } from 'react-router-dom';
import routes from '@routes';

const PrivateRoute = inject('authStore')(
  observer(({ authStore, component: RouteComponent, ...rest }) => (
    <Route
      {...rest}
      render={routeProps =>
        authStore.isAuthenticated() ? (
          <RouteComponent {...routeProps} />
        ) : (
          <Redirect to={routes.auth.signIn} />
        )
      }
    />
  )),
);

export default PrivateRoute;
