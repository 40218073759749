import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, CssBaseline, Button } from '@material-ui/core';

import { inject, observer } from 'mobx-react';
import { Error } from '@containers/signIn/components/FormError';
import { LoginTextField } from '@containers/signIn/components/LoginTextField';
import logo from '@app/assets/logo.png';
import { useParams } from 'react-router';
import { PageContainer } from '@styles/globalStyles';

const useStyles = makeStyles(theme => ({
  paper: {
    boxShadow: '0px 1px 13px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'white',
    padding: '30px 20px 0px 30px',
    borderRadius: '10px',
    color: '#808080',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(1, 0, 2),
    backgroundColor: '#24255f',
    color: 'white',
  },
  subtitle: {
    marginTop: 10,
    fontSize: 20,
  },
  signout: {
    marginTop: 10,
    fontSize: 20,
    color: '#eb1765',
  },
  forbidden: {
    marginTop: 30,
    fontSize: 30,
    color: '#eb1765',
  },
}));

const ResetPasswordPage = ({
  authStore: { resetPassword, authError, newPassword },
}) => {
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [otp, setOtp] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const { id } = useParams();

  async function submit(event) {
    event.preventDefault();
    await resetPassword(email, otp);
  }

  async function submitNewPassword(event) {
    event.preventDefault();
    await newPassword(password, passwordConfirmation, id);
  }

  return (
    <PageContainer>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt="BriefcaseSSO" style={{ height: 160 }} />
          <div className={classes.subtitle}>Reset password</div>
          {id && (
            <form
              className={classes.form}
              noValidate
              onSubmit={submitNewPassword}
            >
              <LoginTextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="New password"
                type="password"
                id="password"
                autoComplete="current-password"
                onInput={e => setPassword(e.target.value)}
              />
              <LoginTextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="password"
                label="Password confirmation"
                type="password"
                id="password"
                autoComplete="current-password"
                onInput={e => setPasswordConfirmation(e.target.value)}
              />
              {authError !== '' && <Error>{authError}</Error>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Reset password
              </Button>
            </form>
          )}
          {!id && (
            <form className={classes.form} noValidate onSubmit={submit}>
              <LoginTextField
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                onInput={e => setEmail(e.target.value.toLowerCase())}
                autoFocus
              />
              <LoginTextField
                variant="outlined"
                margin="normal"
                fullWidth
                name="otp"
                label="Authentication token"
                type="otp"
                id="otp"
                onInput={e => setOtp(e.target.value.trim().replace(' ', ''))}
              />
              {authError !== '' && <Error>{authError}</Error>}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Reset password
              </Button>
            </form>
          )}
        </div>
      </Container>
    </PageContainer>
  );
};

export default inject('authStore')(observer(ResetPasswordPage));
