import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, CssBaseline } from '@material-ui/core';
import logo from '@app/assets/logo.png';
import { history } from '@app/history';
import routes from '@routes';
import { inject, observer } from 'mobx-react';
import { PageContainer } from '@styles/globalStyles';

const useStyles = makeStyles(() => ({
  paper: {
    boxShadow: '0px 1px 13px 0px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    backgroundColor: 'white',
    padding: '30px 30px 0px 30px',
    borderRadius: '10px',
    color: '#808080',
  },
  forbidden: {
    marginTop: 30,
    fontSize: 30,
    color: '#eb1765',
  },
  services: {
    margin: 30,
    marginBottom: 0,
    backgroundColor: '#24255f',
    color: 'white',
  },
  signOut: {
    margin: 30,
    backgroundColor: '#24255f',
    color: 'white',
  },
}));

const ForbiddenPage = ({ authStore: { isAuthenticated, logout } }) => {
  const classes = useStyles();
  const auth = isAuthenticated();
  return (
    <PageContainer>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img src={logo} alt="BriefcaseSSO" style={{ height: 160 }} />
          <div className={classes.forbidden}>
            You do not have access to this service.
          </div>
          {auth && (
            <Button
              onClick={() => history.push(routes.services)}
              fullWidth
              variant="contained"
              color="primary"
              className={classes.services}
            >
              Services
            </Button>
          )}
          <Button
            onClick={() => logout()}
            fullWidth
            variant="contained"
            color="primary"
            className={classes.signOut}
          >
            Sign out
          </Button>
        </div>
      </Container>
    </PageContainer>
  );
};

export default inject('authStore')(observer(ForbiddenPage));
